<template>
  <v-container style="min-height: 93vh">
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-list dark color="rgba(0, 0, 0, .2)" class="mt-5 text-left">
          <v-list-item>
            /
          </v-list-item>

          <v-list-group
          :key="index"
            v-for="(item, index) in menuItems"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>

            <div
              :key="index2"
              v-for="(Item, index2) in item.items"
            >
              <v-list-item
                link
                v-if="!Item.rootOnly || isRoot"
                :href="Item.link"
                target="_blank"
              >
                <v-list-item-subtitle v-text="Item.title"></v-list-item-subtitle>
              </v-list-item>
            </div>

            <div>
              <v-list-group
                :key="index2"
                v-for="(subitem, index2) in item.subgroup"
                :value="false"
                sub-group
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <div
                  :key="index3"
                  v-for="({title, link, rootOnly}, index3) in subitem.items"
                >
                  <v-list-item
                    link
                    v-if="!rootOnly || isRoot"
                    :href="link"
                    target="_blank"
                  >
                    <v-list-item-subtitle v-text="title"></v-list-item-subtitle>
                  </v-list-item>
                </div>
              </v-list-group>
            </div>
          </v-list-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  created() {
    this.animateLogo()
    this.isRoot = this.$route.meta.root
  },
  data:() => ({
    nameLogo: '',
    isRoot: 1,
    menuItems: [
      {
        title: '/Portfólio',
        items: [
          {
            title: 'github/Gugabit',
            link: 'https://github.com/Gugabit'
          },
          {
            title: 'Carol_Juskit',
            link: 'https://caroljuskit.com'
          },
          {
            title: 'Asteroids',
            link: 'https://asteroids.gugabit.com'
          }
        ],
        subgroup: []
      },
      {
        title: '/Sobre',
        items: [
          {
            title: 'Nothing_here.exe',
            link: ''
          },
        ],
      },
      {
        title: '/Contato',
        items: [
          {
            title: 'SEND AN EMAIL',
            link: 'mailto:gugabit2810@gmail.com'
          },
        ]
      },
      {
        title: '/Downloads',
        items: [],
        subgroup: [
          {
            title: '/tools',
            items: [
              {
                title: 'Ativador_RATON_10_2015',
                rootOnly: true,
                link: 'https://storage.gugabit.com/RATON%20ATIV%2010%202015.rar'
              },
              {
                title: 'Ativador_RATON_KMS_OFFLINE',
                rootOnly: true,
                link: 'https://storage.gugabit.com/RATON-KMSOFFLINE.rar'
              },
              {
                title: 'CPU-Z',
                link: 'http://download.cpuid.com/cpu-z/cpu-z_1.91-en.zip'
              },
              {
                title: 'HandBrake',
                link: 'https://handbrake.fr/rotation.php?file=HandBrake-1.3.1-x86_64-Win_GUI.exe'
              },
              {
                title: 'Yumi',
                link: 'https://www.pendrivelinux.com/downloads/YUMI/YUMI-2.0.6.9.exe'
              },
              {
                title: 'PhotoShop_Portable',
                rootOnly: true,
                link: 'https://drive.google.com/file/d/0ByTz4rsBq1nYYzB6bXNTWXdOM3M/view?usp=sharing'
              },
            ]
          },
          {
            title: '/overClocking',
            items: [
              {
                title: 'Afterburner',
                link: 'http://download.msi.com/uti_exe/vga/MSIAfterburnerSetup.zip'
              },
              {
                title: 'Kombustor',
                link: 'https://geeks3d.com/dl/get/622'
              },
              {
                title: 'Heaven',
                link: 'https://benchmark.unigine.com/heaven'
              },
            ]
          },
        ],
      }
    ],
  }),
  methods: {
    async animateLogo () {
      const wait = async (time) => new Promise((resolve) => setTimeout(resolve, time))
      const text = 'Gugabit.exe'
      for(let i = 0; i < text.length; i++) {
        this.nameLogo.replace('_', '')
        this.nameLogo += text[i]
        await wait(150)
      }
      while(text) {
        this.nameLogo += '_'
        await wait(800)
        this.nameLogo = this.nameLogo.replace('_', '')
        await wait(500)
      }
    }
  }
}
</script>

<style>
  #logo {
    /* top: 100px; */
    transition: ease 1s;
  }

  #gugabit-title {
    color: greenyellow;
    font-size: 32pt;
  }
  
  .logo-top {
    position: relative;
  }
  
  .logo-center {
    position: absolute;
  }
  
  /* ANIMATIONS */


</style>
