/* eslint-disable no-unused-vars */
<template>
  <v-app id="app">
    <v-app-bar app dark>
      <v-img src="./assets/btn.png" height="180%" style="left: ; top: 30%" width="10px" contain />
    </v-app-bar>
    <v-fade-transition>
      <router-view class="mt-16" />
    </v-fade-transition>
  </v-app>
</template>

<script>
export default {

}
</script>

<style lang="scss">
#app {
  font-family: 'Roboto Mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#app {
  background: linear-gradient(-300deg, rgba(32, 32, 32, 0.877), rgba(27, 27, 27, 0.664), rgba(0, 0, 0, 0.897), rgba(39, 39, 39, 0.884));
  background-size: 400% 500%;
  animation: gradient 40s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}
</style>
