<template>
  <v-container>
    <div style="position: relative; margin-top: 25px;" class="ma-4">
      <v-fade-transition>
        <span id="gugabit-title" class="">{{ nameLogo }}</span>
      </v-fade-transition>
    </div>
    <v-row justify="end">
      <v-col align="end">
        <v-fade-transition>
          <v-img id="logo" class="logo-top" height="58vh" contain src="../assets/logo.svg"></v-img>
        </v-fade-transition>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col align="center">
        <v-fade-transition>
          <v-btn
            id="entryBtn"
            rounded
            color="#008700"
            dark
            @click="selected = !selected"
            to="/home"
          >
            ENTRAR
          </v-btn>
        </v-fade-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Home',
  created() {
    this.animateLogo()
  },
  data:() => ({
    nameLogo: ''
  }),
  methods: {
    async animateLogo () {
      const wait = async (time) => new Promise((resolve) => setTimeout(resolve, time))
      const text = 'Gugabit.exe'
      for(let i = 0; i < text.length; i++) {
        this.nameLogo.replace('_', '')
        this.nameLogo += text[i]
        await wait(150)
      }
      while(text) {
        this.nameLogo += '_'
        await wait(800)
        this.nameLogo = this.nameLogo.replace('_', '')
        await wait(500)
      }
    }
  }
}
</script>

<style scoped>
  #logo {
    /* top: 100px; */
    transition: ease 1s;
  }

  #app {
    background: linear-gradient(-300deg, rgba(73, 73, 73, 0.664), rgba(27, 27, 27, 0.664), rgb(51, 2, 2), rgba(0, 0, 0, 0.877), rgb(0, 0, 0), rgba(73, 73, 73, 0.664));
    background-size: 400% 500%;
    animation: gradient 40s ease infinite;
  }

  #gugabit-title {
    color: greenyellow;
    font-size: 32pt;
  }
  
  .logo-top {
    position: relative;
  }
  
  
  /* ANIMATIONS */


</style>